import { UserAssignmentAttempts } from 'pages/evaluations/assignments_user_attempts/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'assignments/user/attempts',
  label: 'Tentativas do aluno',
  show: UserAssignmentAttempts,
  actions: ['list', 'show'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
