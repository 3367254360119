/* eslint-disable unicorn/filename-case */
export const certificationIcon = (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33333 13.333H2.33333C1.59695 13.333 1 12.7361 1 11.9997V2.66634C1 1.92996 1.59695 1.33301 2.33333 1.33301H11.6667C12.403 1.33301 13 1.92996 13 2.66634V11.9997C13 12.7361 12.403 13.333 11.6667 13.333H10.6667M7 12.6663C8.10457 12.6663 9 11.7709 9 10.6663C9 9.56177 8.10457 8.66634 7 8.66634C5.89543 8.66634 5 9.56177 5 10.6663C5 11.7709 5.89543 12.6663 7 12.6663ZM7 12.6663L7.01429 12.6662L4.88578 14.7947L3.00016 12.9091L5.0131 10.8962M7 12.6663L9.12854 14.7947L11.0142 12.9091L9.00122 10.8962M5 3.99967H9M3.66667 6.33301H10.3333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
