import {
  AssessmentCreate,
  AssessmentEdit,
  AssessmentList,
  AssessmentShow
} from 'pages/evaluations/assessments';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'assessments',
  label: 'Avaliações',
  list: AssessmentList,
  edit: AssessmentEdit,
  create: AssessmentCreate,
  show: AssessmentShow,
  actions: ['list', 'edit', 'create', 'delete', 'show'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
