import { Button, Table, Typography } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import {
  IAssessmentAttempt,
  IAssessmentAttemptShow,
  IAssessmentQuestion
} from 'interfaces/assessments';
import {
  RefreshButton,
  Show,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { UserAttemptsBreadcrumbs } from './components/UserAttemptsBreadcrumbs';
import { getAPI } from 'requests/api';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateAssessmentAttemptStatus } from 'services/assessments';
import { useNavigate, useParams } from 'react-router-dom';

const { Text, Title } = Typography;

export const UserAssessmentAttemptShow = () => {
  const params = useParams();
  const assessmentId = params.assessmentId;
  const userId = params.id;
  const navigate = useNavigate();

  const { tableProps, tableQueryResult } = useTable<IAssessmentAttemptShow>({
    resource: `assessments/attempts`,
    filters: {
      permanent: [
        {
          field: 'filter[by_user_id]',
          value: userId,
          operator: 'eq'
        },
        {
          field: 'filter[by_assessment_id]',
          value: assessmentId,
          operator: 'eq'
        }
      ]
    }
  });

  const { data, isLoading } = tableQueryResult;
  const assessment = data?.data.at(0)?.assessment;

  const onStartEvaluation = async (
    attemptId: string,
    attemptStatus: string
  ) => {
    if (attemptStatus === 'completed') {
      await getAPI()
        .put(`/admin/assessments/attempts/${attemptId}/start_evaluation`, {
          method: 'PUT'
        })
        .then(() =>
          navigate(
            `/teaching_resources/assessments/${assessmentId}/user_attempts/edit/${attemptId}`
          )
        );
    } else {
      navigate(
        `/teaching_resources/assessments/${assessmentId}/user_attempts/edit/${attemptId}`
      );
    }
  };

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar avaliação"
      headerButtons={<RefreshButton />}
      breadcrumb={
        <UserAttemptsBreadcrumbs
          items={[
            {
              title: 'Tentativas do aluno'
            }
          ]}
        />
      }
    >
      <>
        <div style={{ marginBottom: 16 }}>
          <Title level={4} style={{ marginBottom: 0 }}>
            Avaliação: {assessment?.name}
          </Title>
          <Text>Curso: {assessment?.course_name}</Text>
        </div>

        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="attempt"
            key="attempt"
            title="Tentativa"
            render={(value, _, index) => (
              <TextField value={`${index + 1}ª tentativa`} />
            )}
          />

          <Table.Column
            dataIndex="score"
            key="score"
            title="Nota"
            align="center"
            render={value => <TextField value={value} />}
          />

          <Table.Column
            dataIndex="status"
            key="status"
            title="Status"
            render={(value: IAssessmentQuestion['status']) => (
              <TagField
                color={tagColorByStatus(value)}
                style={{ border: '1px solid #d9d9d9' }}
                value={translateAssessmentAttemptStatus(value)}
              />
            )}
          />

          <Table.Column
            title="Ações"
            dataIndex="id"
            render={(value, record: IAssessmentAttempt) => {
              return (
                <Button
                  disabled={
                    !['completed', 'evaluation_started'].includes(record.status)
                  }
                  onClick={() => onStartEvaluation(value, record.status)}
                  size="small"
                  icon={<FileSearchOutlined />}
                >
                  Corrigir
                </Button>
              );
            }}
          />
        </Table>
      </>
    </Show>
  );
};
