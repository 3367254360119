import { Breadcrumb, BreadcrumbProps, Space } from 'antd';
import { teachingIcon } from 'assets/icons/teaching';
import { useParams } from 'react-router-dom';

interface UserAttemptsBreadcrumbsProps {
  items: BreadcrumbProps['items'];
}

export const UserAttemptsBreadcrumbs = ({
  items = []
}: UserAttemptsBreadcrumbsProps) => {
  const params = useParams();
  const assessmentId = params.assessmentId;

  const defaultItems: BreadcrumbProps['items'] = [
    {
      title: (
        <Space size="small" style={{ gap: 4 }}>
          {teachingIcon}
          Recursos de Ensino
        </Space>
      )
    },
    {
      title: 'Avaliações',
      href: '/teaching_resources/assessments'
    },
    {
      title: 'Visualizar',
      href: `/teaching_resources/assessments/show/${assessmentId}?tab=attempts`
    }
  ];

  return <Breadcrumb items={[...defaultItems, ...items]} />;
};
