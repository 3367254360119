import {
  UserAssessmentAttemptEdit,
  UserAssessmentAttemptShow
} from 'pages/evaluations/assessment_user_attempts';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'user_attempts',
  label: 'Tentativas do aluno',
  hide: true,
  edit: UserAssessmentAttemptEdit,
  show: UserAssessmentAttemptShow,
  actions: ['edit', 'show'],
  parentName: 'teaching_resources/assessments/:assessmentId'
};

export default generateResources(resource);
