import { certificationIcon } from '../assets/icons/certification';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'certification',
  label: 'Gestão de Certificados',
  icon: certificationIcon
};

export default generateResources(resource);
