import FaqCoordinators from './FaqCoordinators';
import ForumReports from './ForumReports';
import _academic from './_academic';
import _admin from './_admin';
import _audit from './_audit';
import _b2b from './_b2b';
import _certification from './_certification';
import _courses from './_courses';
import _deal from './_deal';
import _ecommerce from './_ecommerce';
import _finance from './_finance';
import _sales from './_sales';
import _teaching from './_teaching';
import academic_record_equivalencies from './AcademicRecordEquivalencies';
import academic_records from './academic_records/AcademicRecords';
import action_logs from './ActionLogs';
import agents from './Agents';
import assessment_questions from './assessments/AssessmentQuestions';
import assessment_user_attempts from './assessments/UserAttempts';
import assessments from './assessments/Assessments';
import assessments_attepts from './assessments/AssessmentsAttempts';
import assignment_attempts from './assignments/AssignmentAttempts';
import assignments from './assignments/Assignments';
import bonuses from './Bonuses';
import bulk_bonuses from './BulkBonuses';
import bulk_user_suspends from './BulkUserSuspends';
import bulk_users from './BulkUsers';
import certifying_institutions from './Certifiers';
import classrooms from './Classrooms';
import comments from './Comments';
import companies from './Companies';
import contents from './libraries/Contents';
import contracts from './Contracts';
import coupons from './Coupons';
import course_certificates from './CourseCertificates';
import courses from './Courses';
import discussion_threads from './DiscussionThreads';
import document_templates from './DocumentTemplates';
import documentation_association from './DocumentationAssociation';
import documentations from './Documentations';
import ecommerce_divisions from './EcommerceDivisions';
import ecommerce_profit_centers from './EcommerceProfitCenters';
import epimetheus_groups from './epimetheus/Groups';
import epimetheus_tags from './epimetheus/Tags';
import equivalencies from './Equivalencies';
import fees from './Fees';
import forum from './Forum';
import graduate_courses from './GraduateCourses';
import graduate_disciplines from './GraduateDisciplines';
import graduate_enrollment from './users/GraduateEnrollment';
import graduate_modules from './GraduateModules';
import instructors from './Instructors';
import interactive_classes from './InteractiveClasses';
import libraries from './Libraries';
import log_deleted_users from './LogDeletedUsers';
import manualOrders from './ManualOrder';
import noticeBoards from './NoticeBoards';
import offer_campaign_assets from './OfferCampaignAssets';
import offer_campaigns from './OfferCampaigns';
import offer_gateway_rules from './OfferGatewayRules';
import order_not_refunds from './OrderNotRefunds';
import order_payment_plan from './OrderPaymentPlan';
import order_payment_refunds from './OrderPaymentRefunds';
import order_refunds from './OrderRefunds';
import orders from './Orders';
import payment_plan_deals_discounts from './discount_campaings/PaymentPlanDealsDiscounts';
import payment_plans from './PaymentPlans';
import personifications from './Personifications';
import possessions from './Possession';
import product_bundles from './ProductBundles';
import product_categories from './ProductCategories';
import product_sets from './ProductSets';
import products from './Products';
import professors from './Professors';
import prometheus_lives from './prometheus/Lives';
import prometheus_videos from './prometheus/Videos';
import reports from './Reports';
import roles from './Roles';
import signaturepartners from './SignaturePartners';
import signatures from './Signatures';
import testimonials from './Testimonials';
import user_assignments from './assignments/UserAttempts';
import users from './users/Users';
import {
  IAppResource,
  IResource,
  IResourceAndRoutes
} from 'interfaces/resources';
import { getTenant } from 'services/tenants';

export const RESOURCES: IResourceAndRoutes[] = [
  // Gestão Acadêmica
  _academic,
  users,
  graduate_enrollment,
  instructors,
  professors,
  assignment_attempts,
  assessments_attepts,
  comments,
  reports,
  ForumReports,
  document_templates,
  documentations,
  documentation_association,
  equivalencies,
  academic_records,
  academic_record_equivalencies,
  discussion_threads,
  noticeBoards,
  FaqCoordinators,
  forum,

  // Recursos de Ensino
  _teaching,
  libraries,
  contents,
  interactive_classes,
  prometheus_videos,
  prometheus_lives,
  assessment_questions,
  assessments,
  assessment_user_attempts,
  assignments,
  user_assignments,
  epimetheus_groups,
  epimetheus_tags,

  // Montagem de cursos
  _courses,
  courses,
  graduate_courses,
  graduate_modules,
  graduate_disciplines,
  classrooms,

  // Gestão de certificados
  _certification,
  course_certificates,
  certifying_institutions,
  signatures,
  signaturepartners,

  // Loja Online
  _ecommerce,
  products,
  offer_campaigns,
  offer_campaign_assets,
  product_categories,
  product_sets,
  product_bundles,
  testimonials,
  possessions,

  // Vendas
  _sales,
  coupons,
  manualOrders,
  orders,
  ecommerce_divisions,
  payment_plans,
  payment_plan_deals_discounts,

  // Financeiro
  _finance,
  offer_gateway_rules,
  ecommerce_profit_centers,
  order_refunds,
  order_not_refunds,
  order_payment_refunds,

  // Inadimplência
  _deal,
  fees,

  // Auditoria
  _audit,
  log_deleted_users,

  // B2B
  _b2b,
  companies,
  contracts,

  // Admin
  _admin,
  roles,
  agents,
  bonuses,
  personifications,
  action_logs,

  bulk_bonuses,
  bulk_users,
  bulk_user_suspends,
  order_payment_plan
];

export const Routes = [...users.routes, ...instructors.routes];

const tenant = getTenant();

export const getResourcesAndRoutes = (): IResourceAndRoutes => {
  const routes: JSX.Element[] = [];
  const resource = RESOURCES.map(
    (resourceAndRoutes: IResourceAndRoutes): IAppResource | undefined => {
      const resource = resourceAndRoutes.resource as IResource;
      routes.push(...resourceAndRoutes.routes);

      if (
        tenant.disabledFeatures?.includes(resource.name) ||
        tenant.disabledFeatures?.includes(resource.parentName || '') ||
        resource.onlyPermission
      ) {
        return;
      }

      return {
        name: resource.name,
        list: resource.list,
        show: resource.show,
        edit: resource.edit,
        create: resource.create,
        icon: resource.icon,
        parentName: resource.parentName,
        options: {
          label: resource.label,
          route: `${resource.parentName}/${resource.route || resource.name}`,
          hide: resource.hide
        }
      };
    }
  ).filter(
    (resource: IAppResource | undefined): resource is IAppResource => !!resource
  );

  return {
    resource,
    routes
  };
};
